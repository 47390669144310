/** @jsx jsx */
import { jsx, Box, Close, Input } from 'theme-ui'
import React from 'react'
import { connectSearchBox } from "react-instantsearch-dom"

const Search = ({ currentRefinement, refine }) => {
  return (
    <Box sx={{position: "relative"}}>
      <Input type="search" value={currentRefinement} placeholder="Search" onChange={e => refine(e.currentTarget.value)} sx={{width: "100%"}} />
      <Close
        sx={{
          display: currentRefinement.trim().length ? "block" : "none",
          position: "absolute",
          top: "0",
          right: "0",
          height: "100%"
          
        }}
        onClick={() => refine("")}
      />
    </Box>
  )
}
const SearchBox = connectSearchBox(Search)

export default SearchBox