/** @jsx jsx */
import { jsx, Box, Heading, Flex, Text } from "theme-ui"
import React from "react"
import { graphql, Link } from "gatsby"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  Configure,
} from "react-instantsearch-dom"

import SEO from "../components/seo"
import Layout from "../components/layout"
import SearchBox from "../components/newsPage/SearchBox"
import Results from "../components/newsPage/Results"
import createPaginationArray from "../utils/createPaginationArray"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

export default function PostsPage({data, pageContext}) {
  const { currentPage, numPages } = pageContext
  const paginationArray = createPaginationArray(currentPage, numPages)
  return (
    <Layout>
      <SEO title="Home" />
      <Box variant="layout.wrapper.medium">
        <Heading as="h1">Posts</Heading>
        <Box>
          <InstantSearch indexName="Posts" searchClient={searchClient}>
            <Box sx={{position: "relative"}}>
              <Configure hitsPerPage={8} />
              <SearchBox />
              <Results />
            </Box>
          </InstantSearch>
        </Box>
        <Box sx={{mt: 5}}>
          {data.allBlogPost.nodes.map(({ date, excerpt, id, slug, tags, title }) => (
            <Box key={id} mb={5}>
              <Heading as="h2">
                <Link
                  sx={{ variant: "links.default" }}
                  to={`/news/${slug}`}
                >
                  {title}
                </Link>
              </Heading>
              <Box sx={{mb: 3}}>
                <Text sx={{pr: 2}}>Posted on {date}</Text>
                {tags.map(tag => <Text key={tag} variant="pill">{tag}</Text>)}
              </Box>
              <Text dangerouslySetInnerHTML={{__html: excerpt}} />
            </Box>
          ))}
        </Box>
        <Flex sx={{flexDirection: "column", alignItems: "center"}}>
          <Box>
            {paginationArray.map(page => {
              const pageObject = {}
              if (typeof page === "number") {
                pageObject.label = page
                pageObject.to = page
              } else {
                pageObject.label = page[0]
                pageObject.to = page[1]
              }
              const activeSx = currentPage === pageObject.label
                ? {
                    fontWeight: "semibold",
                    bg: "navy90",
                    borderColor: "navy90",
                    color: "#fff"
                  }
                : {}
              return <Link key={pageObject.label} sx={{variant: "links.paginationItem", ...activeSx}} to={page === 1 ? `/news` : `/news/${pageObject.to}`} >{pageObject.label}</Link>
            })}
          </Box>
          <Box sx={{mt: 2}}>Showing page {currentPage} of {numPages}</Box>
        </Flex>
      </Box>
    </Layout>
  )
}

export const blogListQuery = graphql`
  query blogListQuery($limit: Int!, $skip: Int!) {
    allBlogPost(
      limit: $limit
      skip: $skip
      sort: {
        fields: date
        order: DESC
      }
    ) {
      nodes {
        id
        title
        slug
        date(formatString: "MMMM DD, YYYY")
        excerpt
        tags
      }
    }
  }
`