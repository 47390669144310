/** @jsx jsx */
import { jsx, Box, Flex, Text } from 'theme-ui'
import React from 'react'
import { Link } from 'gatsby'
import {
  connectHits,
  connectStateResults,
} from "react-instantsearch-dom"

import Pagination from './Pagination'

const sxHits = {
  position: "absolute",
  bg: "white",
  width: "100%",
  boxShadow: "0 4px 8px 1px rgba(0,0,0,0.1)",
  mt: 2,
  borderRadius: "4px"
}

const sxHit = {
  py: 3,
  px: 4,
  borderBottom: "1px solid #eee"
}

const DTF = new Intl.DateTimeFormat([], { month: "short", day: "2-digit", year: "numeric" })

const HitsUI = ({ hits }) => {
  return (
    <>
      {hits.map(({slug, tags, date, title}) => {
        const formattedDate = DTF.format(date * 1000)
        return (
          <Box key={slug} sx={sxHit}>
            <Link to={`/news/${slug}`} sx={{variant: "links.default", display: "block", fontWeight: "semibold"}}>{title}</Link>
            <Text sx={{fontSize: 1, mb: 1}}>Posted on {formattedDate}</Text>
            <Box>{tags.map(tag => <Text key={tag} variant="pill">{tag}</Text>)}</Box>
          </Box>
        )
      }
      )}
    </>
  )
}
const Hits = connectHits(HitsUI)

const Results = connectStateResults(props => {
  const { searchState, searchResults } = props
  let Component
  if (searchState && searchState.query && searchState.query.trim().length) {
    if (searchResults.nbHits) {
      Component =
        <Box sx={sxHits}>
          <Hits />
          <Box sx={{py: 3}}><Pagination /></Box>
        </Box>
    }
    else {
      Component = <Box sx={{...sxHits, p: 3}}>No Results found</Box>
    }
  }
  else {
    Component = null
  }
  return Component
})

export default Results