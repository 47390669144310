const createRange = (from, to) => {
  return [...Array((to - from) + 1).keys()].map(i => i + from)
}

const createPaginationArray = (currentPage, nbPages, margin = 2) => {
  const displayLength = (margin * 2) + 1
  if (nbPages <= displayLength) {
    // too short, just show all available pages (no arrows needed)
    return createRange(1, nbPages)
  }
  else if (currentPage - margin < margin) {
    // 1-5
    let range = createRange(1, displayLength)
    return [...range, [">", displayLength + 1]]
  }
  else if (currentPage + margin > nbPages - margin) {
    // X-Lastpage
    let from = nbPages - displayLength
    let range = createRange(from, nbPages)
    return [["<", from - 1], ...range]
  }
  else {
    // range of 5 elements, starting from index (currentPage - margin)
    let from = currentPage - margin
    let to = currentPage + margin
    let range = createRange(from, to)
    return [["<", from - 1], ...range, [">", to + 1]]
  }
}

export default createPaginationArray