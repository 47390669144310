/** @jsx jsx */
import { jsx, Box, Flex } from 'theme-ui'
import React from 'react'
import { connectPagination } from "react-instantsearch-dom"

import createPaginationArray from "../../utils/createPaginationArray"

const PaginationUI = ({ currentRefinement, nbPages, refine, createURL }) => {
  const paginationArray = createPaginationArray(currentRefinement, nbPages)
  return (
    <Flex sx={{flexDirection: "column", alignItems: "center"}}>
      <Box>
        {paginationArray.map(page => {
          const activeSx = currentRefinement === page
          ? {
              fontWeight: "semibold",
              bg: "navy90",
              borderColor: "navy90",
              color: "#fff"
            }
          : {}
          const link = typeof page === "number"
            ? <a key={page} href={createURL(page)} sx={{variant: "links.paginationItem", ...activeSx}} onClick={(e) => {
                e.preventDefault()
                refine(page)
              }}>{page}</a>
            : <a key={page[1]} href={createURL(page[1])} sx={{variant: "links.paginationItem", ...activeSx}} onClick={(e) => {
                e.preventDefault()
                refine(page[1])
              }}>{page[0]}</a>
          return link
        })}
      </Box>
      <Box sx={{mt: 2}}>Showing Page {currentRefinement} of {nbPages}</Box>
    </Flex>
  )
}
const Pagination = connectPagination(PaginationUI)

export default Pagination